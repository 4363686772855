<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Agência" : "Cadastrar Agência" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Principais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="4">
              <span class="primary--text">Código *</span>
              <v-text-field
                v-model="dados.codigo"
                :rules="fieldRules"
                required
                placeholder="Informe o código"
                class="mt-n1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="8">
              <span class="primary--text">Nome *</span>
              <v-text-field
                v-model="dados.nome"
                :rules="fieldRules"
                required
                placeholder="Informe o nome"
                class="mt-n1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <span class="primary--text">CNPJ *</span>
              <v-text-field
                v-model="dados.cnpj"
                :rules="cnpjRules"
                v-mask-cnpj
                required
                placeholder="Informe o cnpj"
                class="mt-n1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="8">
              <span class="primary--text">Descrição *</span>
              <v-text-field
                v-model="dados.descricao"
                :rules="fieldRules"
                required
                placeholder="Informe a descrição"
                class="mt-n1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <span class="primary--text">Telefone *</span>
              <v-text-field
                v-model="dados.telefone"
                v-mask-phone.br
                :rules="fieldRules"
                required
                placeholder="Informe o telefone"
                class="mt-n1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="8">
              <span class="primary--text">E-mail *</span>
              <v-text-field
                v-model="dados.email"
                :rules="emailRules"
                required
                placeholder="Informe o e-mail"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Endereço</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="4">
              <span class="primary--text">CEP</span>
              <v-text-field
                v-model="dados.cep"
                @blur="getCep(dados.cep)"
                :loading="loading"
                v-mask-cep.br
                placeholder="Informe o cep"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Logradouro</span>
              <v-text-field
                v-model="dados.logradouro"
                placeholder="Informe o logradouro"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Número</span>
              <v-text-field
                v-model="dados.numero"
                placeholder="Informe o número"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Bairro</span>
              <v-text-field
                v-model="dados.bairro"
                placeholder="Informe o bairro"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Cidade</span>
              <v-text-field
                v-model="dados.cidade"
                placeholder="Informe a cidade"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Cód. IBGE</span>
              <v-text-field
                v-model="dados.cibge"
                placeholder="Cód. ibge"
                class="mt-n1"
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">UF</span>
              <v-text-field
                v-model="dados.uf"
                placeholder="UF"
                class="mt-n1"
                v-mask="'UU'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card :class="`mb-${id ? '5' : '15'}`">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Gerais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade</span>
              <v-autocomplete
                :items="trechos"
                item-text="nome"
                item-value="id"
                v-model="dados.id_localidade"
                placeholder="Informe a localidade"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Empresa</span>
              <v-autocomplete
                :items="empresas"
                item-text="nome_fantasia"
                item-value="id"
                v-model="dados.id_empresa"
                placeholder="Informe a empresa"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Taxa de Embarque</span>
              <v-text-field
                v-model="dados.taxa_embarque"
                v-mask-decimal.br="2"
                placeholder="Informe a taxa de embarque"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Comissão de Entrega %</span>
              <v-text-field
                v-model="dados.comissao_entrega"
                v-mask-decimal.br="2"
                placeholder="Informe a comissão de entrega"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-15" v-if="id">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Metas para Comissões</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="3">
              <span class="primary--text">Meta R$</span>
              <v-text-field
                v-model="dadosMeta.valor_meta"
                v-mask-decimal.br="2"
                placeholder="Informe a meta"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Percentual de Comissão %</span>
              <v-text-field
                v-model="dadosMeta.percentual"
                v-mask-decimal.br="2"
                placeholder="Informe a percentual de comissão"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex align-center mt-n4">
              <v-btn
                depressed
                v-if="dadosMeta.valor_meta && dadosMeta.percentual"
                color="primary"
                dark
                @click="salvarMeta()"
                >Salvar</v-btn
              >
            </v-col>
            <v-col cols="12" sm="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="headers"
                :items="metas"
                class="elevation-0"
                :loading="loader"
              >
                <template v-slot:item.action="{ item }">
                  <v-icon small @click="deleteItem(item.id)" color="error"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import BackArrow from "../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      loader: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      headers: [
        { text: "Meta R$", value: "valor_meta", sortable: false },
        {
          text: "Percentual de Comissão %",
          value: "percentual",
          sortable: false,
        },
        { text: "Ações", align: "right", value: "action", sortable: false },
      ],
      dadosMeta: {
        id_agencia: this.id ? this.id : null,
        valor_meta: 0,
        percentual: 0,
      },
      dados: {
        codigo: "",
        nome: "",
        descricao: "",
        cnpj: null,
        telefone: "",
        email: "",
        cep: null,
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        id_localidade: null,
        id_empresa: null,
        taxa_embarque: "",
      },
      trechos: [],
      empresas: [],
      metas: [],
      valid: true,
      validMetas: true,
      metasRules: [(v) => !!v || "Este campo é requerido"],
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      cnpjRules: [(v) => !!v || "Este campo é requerido"],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
    };
  },
  created() {
    this.loadTrechos();
    this.loadEmpresas();
    if (this.id) {
      this.loadMetas();
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/agencias/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    salvarMeta() {
      if (this.id) {
        this.loader = true;
        this.$http
          .post(`/agencias/metas/create/${this.id}`, this.dadosMeta)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              this.loadMetas();
              this.loader = false;
              this.dadosMeta.percentual = 0;
              this.dadosMeta.valor_meta = 0;
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
              this.loader = false;
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
            this.loader = false;
          });
      }
    },
    deleteItem(id) {
      let c = confirm("Deseja excluir essa meta?");
      if (c) {
        this.loader = true;
        this.$http
          .post(`/agencias/metas/delete/${id}`)
          .then((r) => {
            this.message.text = r.data.success;
            this.message.snacshow = true;
            this.message.type = "success";
            this.loadMetas();
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
            this.loader = false;
          });
      }
    },
    loadMetas() {
      this.loader = true;
      this.$http
        .get(`/agencias/metas/${this.id}`)
        .then((r) => {
          this.metas = r.data;
          this.$store.dispatch("activeLoader", false);
          this.loader = false;
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
          this.loader = false;
        });
    },
    loadEmpresas() {
      this.$http
        .get(`/empresas`)
        .then((r) => {
          this.empresas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    loadTrechos() {
      this.$http
        .get(`/localidades`)
        .then((r) => {
          this.trechos = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.codigo = this.dados.codigo.toUpperCase();
      this.dados.nome = this.dados.nome.toUpperCase();
      this.dados.descricao = this.dados.descricao.toUpperCase();
      this.dados.logradouro = this.dados.logradouro.toUpperCase();
      this.dados.bairro = this.dados.bairro.toUpperCase();
      this.dados.cidade = this.dados.cidade.toUpperCase();
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/agencias/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/agencias/gerenciar");
              }, 500);
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
          });
      } else {
        this.$http
          .post("/agencias/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/agencias/gerenciar");
              }, 1500);
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
          });
      }
    },
    getCep(cep) {
      if (cep.length == 10) {
        this.loading = true;
        cep = cep.replace("-", "");
        cep = cep.replace(".", "");
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            this.dados.logradouro = response.data.logradouro;
            this.dados.bairro = response.data.bairro;
            this.dados.cidade = response.data.localidade;
            this.dados.uf = response.data.uf;
            this.dados.cibge = response.data.ibge;
            this.loading = false;
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="./style.vue"></style>
